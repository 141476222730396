import React from "react";
import { graphql, navigate } from "gatsby";
import BlogTemplateHeader from "../components/shared/subHeader/blogTemplateHeader";
import ContentBlock from "../components/shared/sub/contentBlock";
import BlogTemplateSection from "./blogTemplateStyle";
import SEO from "../components/shared/seo";
import { SanityCategory } from "@graphql-types";
import { CalculatorPreview, GuidePreview } from "@global";

const BlogTemplate = ({ data }) => {
  const {
    targetBlog: {
      edges: [{ node: blog }],
    },
  } = data;

  const contentHubLink = data.sanityContentHub.slug.current;

  const seoData = {
    title: blog.title,
    slug: blog.slug.current,
    description: blog.excerpt,
    keywords: blog.keywords,
    image: blog.featureImage,
  };

  const firstCategory = (blog.categories && blog.categories.length > 0
    ? blog.categories[0]
    : null) as SanityCategory;

  return (
    <BlogTemplateSection>
      <SEO {...seoData} />
      <div className="subpage-container">
        <BlogTemplateHeader
          title={blog.title}
          date={blog.publishdate}
          author={blog.author}
          category={firstCategory}
          link={contentHubLink}
        />
        <div className="container content">
          <div className="main">
            <ContentBlock blocks={blog.content._rawContent} />
            <div className="disclaimer-content">
              <p>
                Please read our{" "}
                <span onClick={() => navigate("/website-disclaimer")}>Disclaimer Statement</span>{" "}
                for more information.
              </p>
            </div>
          </div>
          {firstCategory.relatedCalculator || firstCategory.relatedGuide ? (
            <div className="side-bar">
              <h3>Related Content</h3>
              {firstCategory?.relatedCalculator && (
                <CalculatorPreview data={firstCategory?.relatedCalculator} />
              )}
              {firstCategory.relatedGuide && <GuidePreview data={firstCategory.relatedGuide} />}
            </div>
          ) : null}
        </div>
      </div>
    </BlogTemplateSection>
  );
};

export const query = graphql`
  query singleBlogQuery($slug: String) {
    targetBlog: allSanityBlogs(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          title
          publishdate(formatString: "Do MMM YYYY")
          excerpt
          category
          author
          keywords
          featureImage {
            asset {
              url
            }
          }
          categories {
            CategoryName
            colorList
            categorySlug {
              current
            }
            relatedCalculator {
              ... on SanityCalculatorMortgageRepaymentPage {
                title
                categories {
                  ...sanityCategory
                }
                seo {
                  slug {
                    current
                  }
                }
              }
              ... on SanityCalculatorFinanceCostPage {
                title
                categories {
                  ...sanityCategory
                }
                seo {
                  slug {
                    current
                  }
                }
              }
              ... on SanityCalculatorEquityPage {
                title
                categories {
                  ...sanityCategory
                }
                seo {
                  slug {
                    current
                  }
                }
              }
              ... on SanityCalculatorDevelopmentFeasibilityPage {
                title
                categories {
                  ...sanityCategory
                }
                seo {
                  slug {
                    current
                  }
                }
              }
              ... on SanityCalculatorBorrowingPage {
                title
                categories {
                  ...sanityCategory
                }
                seo {
                  slug {
                    current
                  }
                }
              }
            }
            relatedGuide {
              title
              description
              slug {
                current
              }
              categories {
                ...sanityCategory
              }
            }
          }
          slug {
            current
          }
          content {
            _rawContent(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
    sanityContentHub {
      slug {
        current
      }
    }
  }
`;

export default BlogTemplate;
