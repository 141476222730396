import styled from "styled-components";

export default styled.section`
  background-color: transparent;

  .container.content {
    display: grid;
    grid-template-columns: 65% 30%;
    grid-gap: 5%;
    padding: 8rem 0 4rem 0;
    max-width: 1200px;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .main {
      * {
        color: var(--red);
      }

      img {
        width: 100%;
        margin: 2rem 0;
      }

      strong {
        font-family: medium;
      }

      p {
        margin-bottom: 2rem;
      }

      ul {
        li {
          position: relative;
          padding-left: 1.8rem;
          margin-bottom: 1rem;
          color: var(--red);

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.7rem;
            display: block;
            width: 0.8rem;
            height: 0.8rem;
            background-color: var(--red);
            border-radius: 1rem;
            /* opacity: 0.8; */
          }
        }
      }
    }

    .side-bar {
      .calculator-preview {
        height: fit-content;
        margin-bottom: 20px;

        & > div {
          width: 100%;
        }
      }

      .guide-preview {
        height: fit-content;

        .svg-shape {
          transform: scale(0.5);
          transform-origin: top right;
        }
        .icon-right,
        .guide-description {
          display: none;
        }
      }
    }

    .author {
      font-family: medium;
      margin-top: 5px;
      /* color: black; */
    }
  }

  .disclaimer-content {
    color: var(--red);
    text-align: left;
    margin: 0px auto 50px auto;
    font-size: 16px;

    span {
      font-family: "bold";
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
      &:hover {
        opacity: 0.6;
      }
      transition: opacity 0.2s;
    }
  }
`;
