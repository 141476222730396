import React from "react"

import { SanityCategory } from "@graphql-types"
import { CategoryTag, Container } from "@util/standard"
import { AiOutlineLeft } from "react-icons/ai"
import MyLink from "../sub/myLink"
import SubHeaderStyle from "./indexStyle"

interface Props {
  title: string
  category?: SanityCategory
  date?: string
  author?: string
  link?: string
}

const BlogTemplateHeader: React.FC<Props> = ({
  title,
  category,
  date,
  author,
  link,
}) => {
  return (
    <SubHeaderStyle>
      <div className="container">
        {category && category.CategoryName && (
          <CategoryTag backgroundColor={"transparent"} className="category">
            {category.CategoryName}
          </CategoryTag>
        )}
        <h1>{title}</h1>
        <Container
          width="100%"
          justifyContent="space-between"
          tabletFlexDirection="column"
        >
          <p>
            {date} | {author}
          </p>
          <MyLink url={`/${link + "#blogsArticles" ?? ""}`}>
            <AiOutlineLeft /> Back
          </MyLink>
        </Container>
      </div>
    </SubHeaderStyle>
  )
}

export default BlogTemplateHeader
